<template>
  <div class="mt-5">
    <Header />
    <Table />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Table from './components/Table.vue'

export default {
  name: 'PersonasMainListado',
  components: {
    Header,
    Table
  }
}
</script>

<style scoped>

</style>
