<template>
<div class="bg-white p-2 rounded-md mt-2">
  <DataTable :value="personas.rows"
             class="p-datatable-customers p-datatable-sm text-sm"
             showGridlines
             dataKey="id"
             v-model:filters="filters1"
             filterDisplay="menu"
             responsiveLayout="scroll"
             removableSort
  >
    <template #header>
      <div class="flex justify-end">
        <div class="flex justify-end">
          <div class="flex">
            <Button type="button"
                    icon="pi pi-filter-slash text-sm"
                    label="Limpiar filtros"
                    class="p-button-outlined text-sm font-medium"
                    @click="clearFilter1()"
            />
          </div>
        </div>
      </div>
    </template>
    <template #empty>
      No hay datos para mostrar
    </template>

    <Column field="fullname" header="Nombre" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.fullname }}
        </p>
      </template>
      <template  #filter="{filterModel}">
        <InputText style="font-size: 15px;" type="text" v-model="filterModel.value"  class="p-column-filter" placeholder="Buscar"/>
      </template>
    </Column>

    <Column field="docTipoId" header="Tipo Documento" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.docTipoId }}
        </p>
      </template>
      <template #filter="{filterModel}">
        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar"/>
      </template>
    </Column>

    <Column field="numeroIdentificacion" header="N° Identificación" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.numeroIdentificacion }}
        </p>
      </template>
      <template #filter="{filterModel}">
        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar"/>
      </template>
    </Column>

    <Column field="telefono" header="Telefono" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.telefono }}
        </p>
      </template>
      <template #filter="{filterModel}">
        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar"/>
      </template>
    </Column>

    <Column field="municipio" header="Municipio" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.localizacion.split(",")[0] }}
        </p>
      </template>
    </Column>

    <Column field="departamento" header="Departamento" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.localizacion.split(",")[1] }}
        </p>
      </template>
    </Column>
    <Column header="Acciones">
      <template #body="{data}">
        <div class="flex gap-4 justify-center">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning" @click="onEditar(data)" />
          <!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="remove(data)" /> -->
        </div>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="paginator.offset"
             :rows="paginator.limit"
             :totalRecords="personas.count"
             :rowsPerPageOptions="[2,10,20,30]"
             @page="onPage($event)"
  />
</div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import storePersonas from '../../../../../store/personaNatural.store'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'

export default {
  name: 'PersonasMainListadoTable',
  setup () {
    const personas = computed(() => storePersonas.getters.personas)
    const paginator = ref({
      offset: 0,
      page: 0,
      limit: 10
    })
    const router = useRouter()
    const filters1 = ref({
      fullname: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      docTipoId: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      numeroIdentificacion: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      telefono: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      }
    })

    const clearFilter1 = () => {
      filters1.value = {
        fullname: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        docTipoId: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        numeroIdentificacion: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        },
        telefono: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        }
      }
    }
    const onEditar = (data) => {
      router.push({ name: 'pharmasan.administrativa.juridica.personas.formulario-editar', params: { id: data.id } })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      paginator.value.offset = first
      paginator.value.page = page
      storePersonas.dispatch('getAll', {
        paginator: paginator.value,
        filters: filters1.value
      })
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina la persona no se puede recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          storePersonas.dispatch('removePersona', id).then(async () => {
            await storePersonas.dispatch('getAll', {
              paginator: paginator.value,
              filters: filters1.value
            })
            Swal.fire(
              'Eliminado!',
              'La persona fue eliminada con exito.',
              'success'
            )
          })
        }
      })
    }
    watch(filters1, (a, b) => {
      paginator.value.offset = 0
      paginator.value.page = 0
      paginator.value.limit = 10
      storePersonas.dispatch('getAll', {
        paginator: paginator.value,
        filters: filters1.value
      })
    })

    onMounted(async () => {
      await storePersonas.dispatch('getAll', {
        paginator: paginator.value,
        filters: filters1.value
      })
    })

    return {
      personas,
      filters1,
      paginator,
      clearFilter1,
      onPage,
      remove,
      onEditar
    }
  }
}
</script>

<style scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
}
</style>
